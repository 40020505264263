"use client";
import {
  CookiePreferences,
  CookieConsentPreferences,
  CookieCategory,
} from "@/components/CookiesConsent/types";
import cookieConsentManager from "./cookieConsentManager";
import { initialiseGTM, initialiseThirdPartyScripts } from "@/components/CookiesConsent/initialiseScripts";
import { pushToDataLayer } from "@/utils/analytics/pushToDataLayer";
import React, { useEffect, useState } from "react";


const COOKIE_EXPIRY = 60;

type ButtonVariant = "primary" | "secondary" | "outline";

interface ConsentButtonProps {
  onClick: () => void;
  variant?: ButtonVariant;
  children: React.ReactNode;
}

const ConsentButton = ({
  onClick,
  variant = "primary",
  children,
}: ConsentButtonProps) => {
  const baseStyles = "px-6 py-2 rounded-sm transition-colors text-sm";
  const variantStyles = {
    primary: "bg-black text-white hover:bg-primary",
    secondary: "bg-gray-500 text-white hover:bg-primary",
    outline:
      "border border-gray-300 hover:bg-primary hover:text-white hover:border-primary",
  };

  return (
    <button
      onClick={onClick}
      className={`${baseStyles} ${variantStyles[variant]}`}
    >
      {children}
    </button>
  );
};



function CookiesConsent(props:CookiePreferences) {
  const [isVisible, setIsVisible] = useState(false);
  const [showPreferences, setShowPreferences] = useState(false);
  const [preferences, setPreferences] = useState<CookieConsentPreferences>({
    necessary: true,
    analytics: false,
    marketing: false,
  });

  useEffect(() => {
    const storedConsent = cookieConsentManager.getConsent();
    initialiseGTM();
    if (!storedConsent) {
      setIsVisible(true);
    } else {
      setPreferences(storedConsent.preferences);
      initialiseThirdPartyScripts(storedConsent.preferences);
    }
  }, []);

  const handleAccept = () => {
    const acceptedPreferences = {
      necessary: true,
      analytics: true,
      marketing: true,
    };
    cookieConsentManager.setConsent(true, acceptedPreferences);
    setIsVisible(false);
    initialiseThirdPartyScripts(acceptedPreferences);
    pushToDataLayer("cookies_consent", {
      consent_given: "true",
      linked: "true",
      consent_preferences: JSON.stringify(acceptedPreferences),
    });
  };

  const handleReject = () => {
    const rejectedPreferences = {
      necessary: true,
      analytics: false,
      marketing: false,
    };
    cookieConsentManager.setConsent(false, rejectedPreferences);
    setIsVisible(false);
    initialiseThirdPartyScripts(rejectedPreferences);

    pushToDataLayer("consent_update", {
      consent_given: "false",
      linked: "false",
      consent: JSON.stringify(rejectedPreferences),
    });
  };

  const handleSavePreferences = () => {
    cookieConsentManager.setConsent(true, preferences);

    pushToDataLayer("consent_update", {
      consent_given: `${preferences.analytics}`,
      linked: `${preferences.analytics}`,
      consent: JSON.stringify(preferences),
    });

    setIsVisible(false);
    setShowPreferences(false);
    initialiseThirdPartyScripts(preferences);
  };

  const handleClose = () => {
    setIsVisible(false);
    const rejectedPreferences = {
      necessary: true,
      analytics: false,
      marketing: false,
    };
    setIsVisible(false);
    initialiseThirdPartyScripts(rejectedPreferences);
  };

  const handleTogglePreference = (category: CookieCategory) => {
    if (props.categories[category].required) return;
    setPreferences((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };
  if (!isVisible) return null;

  return (
    <div className="fixed md:left-base md:bottom-small bottom-0 md:w-[400px] w-full z-[1000]">
      <div className="flex flex-col px-base py-small gap-small bg-neutral border-[0.5px] border-black relative">
        {showPreferences && (
          <button
            onClick={handleClose}
            className="absolute right-base top-small text-gray-500 hover:text-black transition-colors text-xs"
            aria-label="Close cookie preferences"
          >
            [CLOSE]
          </button>
        )}

        <h3 className="md:text-lg text-md font-medium">
          {props.consentPopupTitle}
        </h3>
        <p className="font-light">{props.consentPopupBody}</p>

        {showPreferences ? (
          <>
            <div className="flex flex-col gap-4 my-base">
              {Object.entries(props.categories).map(
                ([key, category]) => (
                  <div key={key} className="flex items-center justify-between">
                    <div className="flex flex-col gap-2">
                      <h4 className="font-medium">{category.title}</h4>
                      <p className="text-sm text-gray-600">
                        {category.definition}
                      </p>
                    </div>
                    <input
                      type="checkbox"
                      checked={preferences[key as CookieCategory]}
                      onChange={() =>
                        handleTogglePreference(key as CookieCategory)
                      }
                      disabled={category.required}
                      className="h-4 w-4 ml-4 rounded accent-primary checked:bg-primary checked:hover:bg-primary focus:ring-primary cursor-pointer disabled:cursor-not-allowed disabled:opacity-50"
                    />
                  </div>
                )
              )}
            </div>
            <ConsentButton onClick={handleSavePreferences}>
              Save Preferences
            </ConsentButton>
          </>
        ) : (
          <div className="flex md:flex-row flex-col gap-base justify-center">
            <ConsentButton onClick={handleAccept}>
              {props.buttons.acceptanceButtonText}
            </ConsentButton>

            <ConsentButton onClick={handleReject} variant="secondary">
              {props.buttons.rejectionButtonText}
            </ConsentButton>

            <ConsentButton
              onClick={() => setShowPreferences(true)}
              variant="outline"
            >
              {props.buttons.preferencesButtonText}
            </ConsentButton>
          </div>
        )}
      </div>
    </div>
  );
}

export default CookiesConsent;

