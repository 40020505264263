import cookieConsentManager from "@/components/CookiesConsent/cookieConsentManager";
import { EventDataMap } from "./types";

declare global {
  interface Window {
    dataLayer: any[];
  }
}

export const pushToDataLayer = <T extends keyof EventDataMap>(
  eventName: T,
  eventData: Omit<
    Omit<EventDataMap[T], "linked"> & { linked?: "true" | "false" },
    "timestamp" | "nonInteraction"
  >,
  nonInteraction: boolean = false
): void => {
  window.dataLayer = window.dataLayer || [];

  const event = {
    event: eventName,
    timestamp: new Date().toISOString(),
    nonInteraction,
    eventData: {
      ...eventData,
      linked:
        eventData.linked !== undefined
          ? eventData.linked
          : cookieConsentManager.getConsent()?.preferences.analytics
    },
  };

  window.dataLayer.push(event);

  if (process.env.NODE_ENV === "development") {
    console.log("GTM Event pushed:", event);
  }
};
