"use client";
import React from "react";
import Typewrite from "./Animations/Typewrite";

export interface IFooterProps {
  mainText: string;
  links: {
    href: string;
    text: string;
  }[];
}

const Footer = (props: IFooterProps) => {
  return (
    <div
      className="relative h-[400px]"
      style={{ clipPath: "polygon(0% 0, 100% 0%, 100% 100%, 0 100%)" }}
    >
      <div className="relative h-[calc(100vh+400px)] -top-[100vh]">
        <div className="h-[400px] sticky top-[calc(100vh-400px)]">
          <Content {...props} />
        </div>
      </div>
    </div>
  );
};

const Content = (props: IFooterProps) => (
  <div className="bg-primary py-small px-base h-full w-full flex flex-col justify-between">
    <div className="flex shrink-0 justify-between text-neutral">
      <div className="flex flex-col gap-2">
        {props.links.map((link, index) => (
          <a
            key={`${link.text}_${index}`}
            href={link.href}
            target="_blank"
            rel="noopener noreferrer"
          >
            {link.text}
          </a>
        ))}
      </div>
      <div className="flex flex-col gap-2">
        <a
          href="#top"
          aria-label="Back to top of page"
          onClick={(e) => {
            e.preventDefault();
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
          className="mb-2 text-neutral cursor-pointer"
        >
          Back to the top ^
        </a>
      </div>
    </div>
    <div className="flex justify-between items-end text-neutral md:flex-row flex-col">
      <h1 className="flex 3xl:text-[270px] text-[14vw] leading-[0.8] mt-10 md:mr-0 mr-auto">
        <Typewrite delay={0.5} text={props.mainText} />
      </h1>
      <p>© copyright 2024</p>
    </div>
  </div>
);

export default Footer;
