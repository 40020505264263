"use client";
import React from "react";
import { motion } from "framer-motion";
import classNames from "classnames";


const LETTER_DELAY = 0.04;
const BOX_FADE_DURATION = 0.125;

const Typewrite = ({
  text,
  className,
  delay=0,
}: {
  text: string;
  className?: string;
  delay?:number
}) => {
  const lines = text.split("\n").map(x=>x.trim());
  let offset = delay;
  return (
    <div className={classNames(className, "flex flex-col")}>
      {lines.map((line, index) => {
        offset += index * LETTER_DELAY * (lines[index - 1]?.length || 0);
        return (
          <div key={`${line}_${index}`} >
            <span className="sr-only">{line}</span>
            <span className="">
              {line.split("").map((l, i) => {
                return (
                  <motion.span className="relative" key={`${l}_${i}`}>
                    <motion.span
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      transition={{
                        delay: offset + i * LETTER_DELAY,
                        duration: 0,
                      }}
                      viewport={{ once: true, amount: 0.5 }} 
                    >
                      {l}
                    </motion.span>
                    <motion.span
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: [0, 1, 0] }}
                      transition={{
                        delay: offset + i * LETTER_DELAY,
                        duration: BOX_FADE_DURATION,
                        ease: "easeInOut",
                        times: [0, 0.1, 0.5],
                      }}
                      viewport={{ once: true, amount: 0.5 }} 
                      className="absolute bottom-[3px] left-[1px] right-0 top-[3px] bg-black"
                    />
                  </motion.span>
                );
              })}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default Typewrite;
