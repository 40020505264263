import { CookieConsentPreferences } from "@/components/CookiesConsent/types";

type ConsentStatus = {
    consented: boolean;
    preferences: CookieConsentPreferences
  } | null;
  
  class CookieConsentManager {
    private static instance = new CookieConsentManager();
    private memoizedConsent: ConsentStatus = null;
    private readonly COOKIE_NAME = 'cookie_consent';
    private readonly cookieMaxAge = 30;
  
    private constructor() {}
  
    public static getInstance(): CookieConsentManager {
      return CookieConsentManager.instance;
    }
  
    public getConsent(): ConsentStatus {
      if (this.memoizedConsent !== null) {
        return this.memoizedConsent;
      }
  
      const cookieValue = this.getCookie(this.COOKIE_NAME);
      if (!cookieValue) {
        return null;
      }
  
      try {
        const parsedConsent = JSON.parse(cookieValue);
        this.memoizedConsent = parsedConsent;
        return this.memoizedConsent;
      } catch (error) {
        console.error('Error parsing cookie consent:', error);
        return null;
      }
    }
  
    public setConsent(consented: boolean, preferences: CookieConsentPreferences): void {
      const consentData = {
        consented,
        preferences,
      };
  
      this.memoizedConsent = consentData;
  
      const daysInSeconds = this.cookieMaxAge * 24 * 60 * 60;
      this.setCookie(
        this.COOKIE_NAME,
        JSON.stringify(consentData),
        daysInSeconds
      );
    }

    private getCookie(name: string): string | null {
      const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
      return match ? decodeURIComponent(match[2]) : null;
    }
  
    private setCookie(name: string, value: string, maxAgeSeconds: number): void {
      const encodedValue = encodeURIComponent(value);
      document.cookie = `${name}=${encodedValue}; max-age=${maxAgeSeconds}; path=/; SameSite=Strict`;
    }
  }
  

  export const useCookieConsent = () => {
    const consentManager = CookieConsentManager.getInstance();
    return {
      setConsent: (consented: boolean, parameters: CookieConsentPreferences) => consentManager.setConsent(consented, parameters),
      getConsentStatus: () => consentManager.getConsent()
    };
  };
  
  export default CookieConsentManager.getInstance();